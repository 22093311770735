html {
  --color-primary : #3fc05f;
  --color-primary-hover : #2fa34c;
  --color-secondary : #2a5ac3;
  --color-secondary-hover : #2b4b91;
  --color-success : #3fc05f;
  --color-success-hover : #2fa34c;
  --color-info : #4099ff;
  --color-info-hover : #0d7eff;
  --color-warning : #f3d800;
  --color-warning-hover : #c0ab00;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Open Sans' !important;
}

/* BTN PRIMARY CUSTOM, SECONDARY CUSTOM */

.btn-primary-custom {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  color: white;
}
.btn-primary-custom:hover {
  background-color: var(--color-primary-hover) !important;
  border-color: var(--color-primary-hover) !important;
}
.btn-primary-custom:focus {
  outline: none;
}
.btn-secondary-custom {
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
  color: white;
}
.btn-secondary-custom:hover {
  background-color: var(--color-secondary-hover) !important;
  border-color: var(--color-secondary-hover) !important;
}

/* BTN SUCCESS, INFO, WARNING,  */
.btn-success {
  background-color: var(--color-success) !important;
  border-color: var(--color-success) !important;
  color: white;
}
.btn-success:hover {
  background-color: var(--color-success-hover) !important;
  border-color: var(--color-success-hover) !important;
}
.btn-primary {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  color: white;
}
.btn-primary:hover {
  background-color: var(--color-primary-hover) !important;
  border-color: var(--color-primary-hover) !important;
}
.btn-secondary {
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
  color: white;
}
.btn-secondary:hover {
  background-color: var(--color-secondary-hover) !important;
  border-color: var(--color-secondary-hover) !important;
}
.btn-info {
  background-color: var(--color-info) !important;
  border-color: var(--color-info) !important;
  color: white;
}
.btn-info:hover {
  background-color: var(--color-info-hover) !important;
  border-color: var(--color-info-hover) !important;
}
.btn-warning {
  background-color: var(--color-warning) !important;
  border-color: var(--color-warning) !important;
  color: white;
}
.btn-warning:hover {
  background-color: var(--color-warning-hover) !important;
  border-color: var(--color-warning-hover) !important;
}

/* BADGE */
.badge-primary {
  background-color: var(--color-primary) !important;
  color: white;
}
.badge-secondary {
  background-color: var(--color-info) !important;
  color: white;
}

/* CUSTOM COLOR */

.primary-custom {
  color: var(--color-primary);
}
.primary-custom:hover {
  color: var(--color-primary-hover);
}

/* BACKGROUND COLOR */
.background-primary { 
  background-color: var(--color-primary);
}
.background-secondary { 
  background-color: var(--color-secondary);
}
.background-info { 
  background-color: var(--color-info);
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
  /* min-height: 100%;
	height: auto !important;
	height: 100%; */
  /* overflow: hidden !important; */
}

#search-bar-0.form-control {
  margin-bottom: 5px;
}

#cetak-surat > table {
  page-break-inside: auto;
}

#cetak-surat > table > tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

#cetak-surat > table > tr > thead {
  display: table-header-group;
}

#cetak-surat > table > tr > tfoot {
  display: table-header-group;
}

.detail-dokumen-administrasi {
  width: 100%;
}
.detail-dokumen-administrasi th, .detail-dokumen-administrasi td {
  padding: 5px;
}

input:focus {
  /* background-color: yellow !important; */
  border-color: #00ff66 !important;
}

.custom-font-sm {
  font-size: 12px;
}

.clearBtnSearch {
  position: relative;
  top: -42px;
  right: 13px;
  display: none;
}

.clearBtnSearch .active {
  display: block;
}

.clearBtnSearch2 {
  position: absolute;
  top: 7px;
  right: 26px;
  display: none;
}

.clearBtnSearch2 .active {
  display: block;
}

.kiosk-card {
  box-shadow: 0 4px 8px 0 #0000003d, 0 6px 20px 0 #0000003d;
  background-color: #ffffffc7;
  cursor: pointer;
}

.kiosk-card:hover {
  box-shadow: 0 4px 8px 0 #0000009e, 0 6px 20px 0 #0000009e;
}

.logout-kiosk:hover {
  box-shadow: 0 4px 8px 0 #ffffff, 0 6px 20px 0 #ffffff;
  background-color: brown;
}

#modalForm::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 17px;
}
#modalForm::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

#bg-kiosk {
  width: 100%;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  background-image: url("../images/kiosk/cv.webp");
  background-position: center;
  background-size: cover;
  /* z-index: -1; */
  /* position: absolute; */
  min-height: 100%;
  height: auto !important;
  height: 100%;
  overflow: hidden !important;
}

#bg-login-kiosk {
  background-image: url("../images/kiosk/cv.webp");
  background-position: center;
  background-size: cover;
}

#bg-login-digidesa {
  background-image: url("../images/kiosk/cv.webp");
  background-position: center;
  background-size: cover;
}

#bg-login-digidesa-new {
  background-image: url("../images/bg-login.webp");
  background-position: center;
  background-size: cover;
}
.inputss {
  position:relative;
  /* padding-left: 20px; */
    /* direction: rtl; */
}

.layanan-baru .tab-content {
  height: 715px;
  overflow: auto;
}

.inputs{
padding-left: 30px;
}

.icon {
  position:absolute;
    bottom:11px;
    left:10px;
    color: slategrey;
    font-size: 17px;
}

i.admin-page.icofont.icofont-info-circle:hover{
  color: #7e7e7e;
}

/* Modal Image */
/* The Modal (background) */
.modal-image {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content-image {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption-image {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content-image, #caption-image {
  animation-name: zoom-image;
  animation-duration: 0.6s;
}

@keyframes zoom-image {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.close-image {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close-image:hover,
.close-image:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
/* End Modal Image */

.parent-thumbnail-table {
  border: 1px solid black;
  width: fit-content;
}

.thumbnail-table-size {
  height: 150px;
  width: 250px;
  object-fit: cover;
}

.ck-content ul {
  list-style-type: inherit;
  padding-inline-start: 40px;
}

@media (max-width: 376px) {
 h3 img{
   height: 50px;
 } 
}

a:hover {
  text-decoration: none !important;
}

a:active {
  text-decoration: none !important;
}

a:focus {
  text-decoration: none !important;
}

input {
  color: black !important;
}

p {
  margin-bottom: 1rem !important;
}

.form-control {
  font-size: 14px;
}

.btn-mt1 {
  margin-top: 10px;
}

.dashboard-over {
  border-radius: 15px;
  border: 1px solid #ccc2c2;
  cursor: pointer;
  background-color: #fff;
  color: #707070;
  text-align: center;
}
.dashboard-over span {
  font-size: 60px;
  text-shadow: 2px 2px 5px black;
}

.dashboard-over:hover {
  background-color: var(--color-primary) !important;
  color: white;
}

.pengajuan-selesai {
  padding: 10px;
  border-radius: 20px;
  word-wrap: break-word;
  cursor: pointer;
  background-color: #006400;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px black;
}

.pengajuan-selesai span {
  font-size: 60px;
}

.pengajuan-selesai:hover {
  background-color: #32cd32;
}

.pengajuan-menunggu-eksekusi {
  border-radius: 20px;
  /* margin-top: 20px; */
  word-wrap: break-word;
  cursor: pointer;
  background-color: #ffa500;
  min-height: 190px;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px black;
}

.pengajuan-menunggu-eksekusi span {
  font-size: 60px;
}

.pengajuan-menunggu-eksekusi:hover {
  background-color: #ffd700;
}

.pengaduan-menunggu-eksekusi {
  border-radius: 20px;
  /* margin-top: 20px; */
  word-wrap: break-word;
  background-color: brown;
  min-height: 190px;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px black;
}

.pengaduan-menunggu-eksekusi span {
  font-size: 60px;
}

.pengaduan-menunggu-eksekusi:hover {
  background-color: red;
}

.catatan-menunggu-eksekusi {
  border-radius: 20px;
  /* margin-top: 20px; */
  word-wrap: break-word;
  cursor: pointer;
  background-color: #006400;
  min-height: 190px;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px black;
}

.catatan-menunggu-eksekusi span {
  font-size: 60px;
}

.catatan-menunggu-eksekusi:hover {
  background-color: #32cd32;
}

.verifikasi-menunggu-eksekusi {
  background-color: #ffa500;
  border-radius: 20px;
  /* margin-top: 20px; */
  word-wrap: break-word;
  cursor: pointer;
  min-height: 190px;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px black;
}

.verifikasi-menunggu-eksekusi span {
  font-size: 60px;
}

.verifikasi-menunggu-eksekusi:hover {
  background-color: #ffd700;
}

.lingkaran {
  background-color: white;
  margin: 0 auto;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 20px;
  margin-left: 20px;
  
}

#bg-beranda-kiosk {
  width: 100%;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  background-image: url("../images/kiosk/cv.webp");
  background-position: center;
  background-size: cover;

  /* display: flex;
  align-items: center; */

  /* width: 100%; */
  /* height: 100vh; */
  /* height: calc(100vw * (9/16)); */
  min-height: 100%;
  height: auto !important;
  height: 100%;
  overflow: auto !important;
}

.bg-beranda-kiosk7 {
  /* width: 100%;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1; */
  background-color: white;

  display: flex;
  align-items: center;

  /* width: 100%; */
  /* height: 100vh; */
  /* height: calc(100vw * (9/16)); */
  min-height: 100%;
  height: auto !important;
  height: 100%;
  overflow: auto !important;
}

.selamat-datang {
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 5px;
  padding: 20px;
  border-radius: 20px;
  word-wrap: break-word;
  min-height: 300px;
  color: white;
  text-align: center;
  font-size: 22px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-shadow: 2px 2px 5px black;
}

.verification-box h6 {
  font-size: 1rem;
}

@media (max-width: 1000px) {
  .selamat-datang {
    font-size: 1.3rem;
  }
}
/* @media (max-width: 920px) {
  .selamat-datang {
    font-size: 1.2rem;
  }
}
@media (max-width: 890px) {
  .selamat-datang {
    font-size: 23px;
  }
}
@media (max-width: 860px) {
  .selamat-datang {
    font-size: 22px;
  }
}
@media (max-width: 828px) {
  .selamat-datang {
    font-size: 21px;
  }
}
@media (max-width: 806px) {
  .selamat-datang {
    font-size: 19px;
  }
} */

.masuk {
  /* background-color: var(--color-primary); */
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  margin: 5px;
  word-wrap: break-word;
  cursor: pointer;
  min-height: 210px;
  color: white;
  text-align: center;
  font-size: 22px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-shadow: 2px 2px 5px black;
}
@media (max-width: 890px) {
  .masuk {
    font-size: 20px;
  }
}

.masuk:hover {
  /* background-color: var(--color-secondary-hover); */
  background-color: var(--color-primary);
}

.keluar {
  /* background-color: var(--color-primary); */
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  margin: 5px;
  word-wrap: break-word;
  cursor: pointer;
  min-height: 210px;
  color: white;
  text-align: center;
  font-size: 22px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-shadow: 2px 2px 5px black;
}
@media (max-width: 890px) {
  .keluar {
    font-size: 20px;
  }
}

.keluar:hover {
  /* background-color: var(--color-secondary-hover); */
  background-color: var(--color-primary);
}

.gambar-laptop {
  /* background-position: center; */
  /* background-color: var(--color-primary); */
  background-color: var(--color-secondary);
  /* margin: 5px; */
  border-radius: 20px;
  word-wrap: break-word;
  min-height: 520px;
  color: white;
  text-align: center;
}

.layanan-baru {
  overflow: auto;
  height: 784px;
  background-color: #f6f7fb;
}

@media (max-width: 767px) {
  .gambar-laptop {
    display: none;
  }
  .layanan-baru {
    height: 500px;
  }
  .pengajuan-selesai span {
    font-size: 80px;
  }
  .verifikasi-menunggu-eksekusi span {
    font-size: 80px;
  }
  .catatan-menunggu-eksekusi span {
    font-size: 80px;
  }
  .pengaduan-menunggu-eksekusi span {
    font-size: 80px;
  }
  .pengajuan-menunggu-eksekusi span {
    font-size: 80px;
  }
}

@media (max-width: 700px) {
  .modal-content-image {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .row .col-sm-2.btn-mt1 {
    margin-top: 0px;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #kertas_antrian,
  #kertas_antrian * {
    visibility: visible;
    /* width: 80mm; */
    /* height: 80mm; */
  }
  #kertas_antrian {
    position: absolute;
    left: 0;
    top: 0;
  }
}

h6 {
  font-size: 0.9rem;
}

.w-max-content {
  width: max-content;
}

.ck-editor__editable {
  min-height: 200px;
}

.ck-content {
  word-break: break-word;
}

/* .styles_modal__gNwvD {
  max-width: none !important;
} */

.table-border-black td {
  border-color: black;
}